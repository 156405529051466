import iconChartWhite from '../../assets/images/common/icon_chart_white.svg';
import iconSearch from '../../assets/images/common/icon_search.svg';
import iconSetting from '../../assets/images/common/icon_setting.svg';
import iconInfo from '../../assets/images/common/icon_info.svg';
import BlockTitle from '../../components/common/BlockTitle';
import RevenueChart from '../../components/home/RevenueChart';
import { useProvinces } from '../../context/ProvinceContext';

interface SectionQuantityProductChartComponentProps {
  title?: string;
}

const SectionQuantityProductChart: React.FC<SectionQuantityProductChartComponentProps> = ({ title }) => {
  const { currentProvince } = useProvinces();

  return (
    <div className="flex-1 rounded-lg bg-white dark:bg-[#15223B]">
      <div className="mb-4 flex h-full flex-col rounded-lg">
        <div className="border-b-2 border-[#E9E9E9] pb-2 pl-6 pr-4 pt-3 dark:border-[#1D2E4F]">
          <div className="flex items-start justify-between">
            <BlockTitle title="Doanh thu trên lượng sản phẩm giao dịch theo thời gian và khu vực" />
            <div className="flex gap-2 pt-1">
              <button className="inline-flex h-8 items-center gap-[6px] rounded-lg px-2">
                <img src={iconSearch} alt="" />
                <span className="text-sm text-[#4B6FB4]">Tìm kiếm</span>
              </button>
              <button className="inline-flex h-8 min-w-max items-center gap-[6px] rounded-lg bg-[#4B84F7] px-2 dark:bg-[#263F70]">
                <img src={iconChartWhite} alt="" />
                <span className="text-sm text-white">Biểu đồ</span>
              </button>
              <button>
                <img src={iconSetting} alt="" />
              </button>
              <button>
                <img src={iconInfo} alt="" />
              </button>
            </div>
          </div>
          <div className="flex items-end">
            <span className="text-[40px] lg:text-[25px] font-semibold leading-8 tracking-tight text-[#629AF7] dark:text-[#5CDBE1]">521,811,987 đ </span>
            <span className="ml-[6px] mb-2 text-base leading-4 tracking-tight text-[#11FF1B]">(-0,6%)</span>
          </div>
          <p className="mt-0 text-sm text-[#4A6497]">Doanh thu trung bình</p>
        </div>
        <div className="flex-1 pb-2 mt-[-20px]">
          <div className="relative h-full px-12 pt-5">
            <RevenueChart />
            <div className="relative flex items-center gap-2">
            <p className="pl-6 text-[10px] font-bold italic text-[#4A6497]">Nguồn: VStore {currentProvince?.name}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionQuantityProductChart;