import iconLive from '../../assets/images/common/icon_live.svg';

interface BlockTitleComponentProps {
  title: string;
}

const BlockTitle: React.FC<BlockTitleComponentProps> = ({ title }) => {
  return (
    <div className="flex flex-1 items-center gap-1 pl-[2px]">
      <img src={iconLive} alt="" />
      <h3 className="pt-[1px] text-base font-semibold leading-6 tracking-[-0.2px]">{ title }</h3>
    </div>
   );
}

export default BlockTitle;