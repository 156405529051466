import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}ecom/api/`

const validateResponseStatus = (status: number): boolean => {
  return status >= 200 && status < 300;
};

class AxiosClient {
  axiosInstance: AxiosInstance;

  constructor() {
    const config: AxiosRequestConfig = {
      baseURL: BASE_URL,
      withCredentials: false,
      validateStatus: validateResponseStatus,
    };

    this.axiosInstance = axios.create(config);

    this.axiosInstance.interceptors.request.use((config: any) => {
      return config;
    });

    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        return Promise.reject(error.response?.data || error);
      }
    );
  }

  public async get<T>(
    path: string,
    params?: Record<string, any>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.get<T>(path, {
        ...config,
        params,
      });

      return response.data;
    } catch (e: any) {
      throw e;
    }
  }
}

const axiosClient = new AxiosClient();
export default axiosClient;
