import axiosClient from "../AxiosClient";

const tradePromotionApi = {
  getSeller: (provinceId: string) => {
    const url = `/seller/v1/trade-promotion/seller-stats?provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getProductStats: (provinceId: string) => {
    const url = `/seller/v1/trade-promotion/product-stats?provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getShopWarehouse: (params: any) => {
    const { page = 1, pageSize = 10, provinceId } = params
    const url = `/seller/v1/trade-promotion/shop-warehouses/list?page=${page}&pageSize=${pageSize}&provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getProductOutput: (provinceId: string) => {
    const url = `/seller/v1/trade-promotion/product-output?provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getTradingSupplier: (params: any) => {
    const { page = 1, pageSize = 10, provinceId } = params
    const url = `/seller/v1/trade-promotion/trading-supplier-variants?page=${page}&pageSize=${pageSize}&provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getTradingProducts: (provinceId: string) => {
    const url = `/seller/v1/trade-promotion/trading-products?provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getCategorizeProductStats: (params: any) => {
    const { page = 1, pageSize = 10, provinceId } = params
    const url = `/seller/v1/trade-promotion/categorize-product-stats?provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getCategorizeProductStatsOutTown: (params: any) => {
    const { categoryId, excludeProvinceId } = params
    function formatCategoryIds(categoryArray: number[]): string {
      return categoryArray.map(id => `&categoryIds=${id}`).join('');
    }
    const formattedString = formatCategoryIds(categoryId);
    const url = `/seller/v1/trade-promotion/categorize-product-stats?excludeProvinceId=${excludeProvinceId}${formattedString}`;
    return axiosClient.get(url);
  },
  getShopDistrictStart: (provinceId: string) => {
    const url = `/seller/v1/trade-promotion/shop-warehouses/district-start?provinceId=${provinceId}`;
    return axiosClient.get(url);
  },
  getRevenueIntervalStats: (params: any) => {
    const { type, provinceId, createdAtFrom  } = params
    const url = `/seller/v1/trade-promotion/revenue-interval-stats?type=${type}&provinceId=${provinceId}&createdAtFrom=${createdAtFrom}`;
    return axiosClient.get(url);
  },
  getRevenueExternalStats: (params: any) => {
    const { type, excludeProvinceId, createdAtFrom  } = params
    const url = `/seller/v1/trade-promotion/revenue-interval-stats?type=${type}&excludeProvinceId=${excludeProvinceId}&createdAtFrom=${createdAtFrom}`;
    return axiosClient.get(url);
  },
};

export default tradePromotionApi;