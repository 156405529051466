import React from 'react';
import SectionStatistics from '../components/home/SectionStatistics';
import SectionProductChart from '../components/home/SectionProductChart';
import SectionTradingProduct from '../components/home/SectionTradingProduct';
import SectionQuantityProductChart from '../components/home/SectionQuantityProductChart';
import SectionShopLocation from '../components/home/SectionShopLocation';
import SectionInfoProduct from '../components/home/SectionInfoProduct';
import SectionMedia from '../components/home/SectionMedia';
import SectionTitle from '../components/common/SectionTitle';
import { useProvinces } from '../context/ProvinceContext';
import ToggleFullscreen from '../components/common/ToggleFullscreen';

const Home: React.FC = () => {
  const { currentProvince } = useProvinces();

  return (
    <div className="flex flex-col gap-[14px]">
      <ToggleFullscreen>
        <section className="flex flex-col gap-[2px] is-full:mt-[0px] mt-[-14px]">
          <SectionStatistics />
          <div className="wrapper flex flex-row gap-4 lg:flex-row">
            <div className="is-full:lg:max-w-[882px] relative flex flex-col gap-4 lg:w-[62.8%]">
              <SectionTitle title={`Biểu đồ sản lượng sản phẩm tỉnh ${currentProvince?.name} và thị trường`} />
              <SectionProductChart />
            </div>
            <div className="flex flex-1 flex-col gap-4 lg:w-[37.2%]">
              <SectionTitle title="Bảng số liệu thống kê" />
              <SectionTradingProduct />
            </div>
          </div>
        </section>
      </ToggleFullscreen>
      <ToggleFullscreen>
        <section className="flex h-full flex-col gap-[14px]">
          <div className="wrapper flex h-full flex-row gap-4 sm:flex-row">
            <div className="flex flex-col gap-4 lg:w-[62.8%]">
              <SectionQuantityProductChart />
            </div>
            <div className="flex flex-1 flex-col gap-4 lg:w-[37.2%]">
              <SectionShopLocation />
            </div>
          </div>
        </section>
      </ToggleFullscreen>
      <ToggleFullscreen>
        <section className="flex flex-col gap-[14px]">
          <div className="wrapper flex gap-4 flex-row">
            <div className="flex h-full flex-col gap-4 w-[62.8%]">
              <SectionInfoProduct />
            </div>
            <div className="flex flex-1 flex-col gap-4 w-[37.2%]">
              <SectionMedia />
            </div>
          </div>
        </section>
      </ToggleFullscreen>
    </div>
  );
};

export default Home;
