import React from 'react';
import RouterConfig from './Router';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { ProvinceProvider, useProvinces } from './context/ProvinceContext';
import { QueryClient, QueryClientProvider } from "react-query";

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ProvinceProvider>
        <ThemeProvider>
          <AppContent />
        </ThemeProvider>
      </ProvinceProvider>
    </QueryClientProvider>
  );
};

const AppContent: React.FC = () => {
  const { theme } = useTheme();
  const { isFullScreen } = useProvinces();
  return (
    <div className={`App ${theme === 'dark' ? 'dark' : ''} ${isFullScreen ? 'is-full' : ''}`}>
      <RouterConfig />
    </div>
  );
}

export default App;
