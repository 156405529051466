
import React, { useState } from 'react';
import BlockTitle from '../../components/common/BlockTitle';
import ProductsTable from '../../components/home/ProductsTable';
import iconSetting from '../../assets/images/common/icon_setting.svg';

interface SectionTradingProductComponentProps {
  title?: string;
}

const SectionTradingProduct: React.FC<SectionTradingProductComponentProps> = ({ title }) => {
  const [activeItem, setActiveItem] = useState('all');

  const handleItemClick = (value: string) => {
    setActiveItem(value);
  };

  const timesFilter = [
    {
      text: '1d',
      value: '1d'
    },
    {
      text: '7d',
      value: '7d'
    },
    {
      text: '1m',
      value: '1m'
    },
    {
      text: '3m',
      value: '3m'
    },
    {
      text: '6m',
      value: '6m'
    },
    {
      text: '1y',
      value: '1y'
    },
    {
      text: 'All',
      value: 'all'
    },
  ]
  
  return (
    <div className="flex-1 rounded-lg bg-white dark:bg-[#15223B]">
      <div className="">
        <div className="relative border-b-2 border-[#E9E9E9] pb-[10px] pl-3 pr-4 pt-3 dark:border-[#1D2E4F]">
          <div className="flex items-start justify-between">
            <BlockTitle title="Sản phẩm đang giao dịch trên địa bàn" />
            <div className="flex gap-2 pt-1">
              <button>
                <img src={iconSetting} alt="" />
              </button>
            </div>
          </div>
          <ul className="mr-2 mt-[46px] flex items-center justify-end gap-[18px]">
            { timesFilter?.map((time, index) => {
              const { text, value } = time;
              return (
                <li
                  key={index}
                  className={`text-xs flex items-center h-7 rounded-lg text-gray-darker cursor-pointer dark:text-white ${value === activeItem ? 'bg-[#4B84F7] text-white px-[18px] dark:bg-[#263F70]' : ''}`}
                  onClick={() => handleItemClick(value)}>
                  { text }
                </li>
              )
            })}
          </ul>
        </div>
        <div className='table-product h-full overflow-auto'>
          <ProductsTable />
        </div>
      </div>
    </div>
  );
}

export default SectionTradingProduct;