import { useState } from "react";
import { useProvinces } from "../../context/ProvinceContext";
import { useQuery } from "react-query";
import tradePromotionApi from "../../api/service/TradePromotionService";
import useWindowDimensions from "../../hooks/useWindowDimensions";

interface data {
  supplierCount: number
  producerCount: number
  shopWarehouseCount: number
}

const SectionStatistics = () => {
  const { currentProvince } = useProvinces();
  const [currentData, setCurrentData] = useState<data>()
  const [productCount, setProductCount] = useState<number>(0)
  
  useQuery(
    ['statistics', currentProvince],
    {
      queryFn: async () => {
        const [sellerData, productStats] = await Promise.all([
          tradePromotionApi.getSeller(currentProvince?.id),
          tradePromotionApi.getProductStats(currentProvince?.id)
        ]);
        return { sellerData, productStats };
      },
      onSuccess: (data: any) => {
        setCurrentData(data.sellerData?.stat);
        setProductCount(data.productStats?.stat?.markethomeProductCount);
      },
      enabled: !!currentProvince,
    },
  );

  return (
    <section className="py-4">
      <div className="wrapper">
        <ul className="flex gap-4">
          <li className="flex-1 rounded-2xl bg-white py-2 pt-0 pt-1 text-center dark:bg-[#15223B]">
            <p className="flex items-center justify-center gap-[2px] pl-[6px] text-[42px] text-primary dark:text-darkPrimary">
              <span className="text-xl font-black"> {currentData?.supplierCount} </span>
              {/* <svg width="34" height="51" viewBox="0 0 34 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.96094 31.0869V25.8574H11.4717V17.9619H16.7012V25.8574H25.2324V31.0869H16.7012V38.9619H11.4717V31.0869H2.96094Z" fill="currentColor"/>
                <path d="M18.9062 18.1211V15.1328H23.7695V10.6211H26.7578V15.1328H31.6328V18.1211H26.7578V22.6211H23.7695V18.1211H18.9062Z" fill="currentColor"/>
              </svg> */}
              <svg width="20" height="40" viewBox="0 0 34 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.96094 31.0869V25.8574H11.4717V17.9619H16.7012V25.8574H25.2324V31.0869H16.7012V38.9619H11.4717V31.0869H2.96094Z" fill="currentColor"/>
                <path d="M18.9062 18.1211V15.1328H23.7695V10.6211H26.7578V15.1328H31.6328V18.1211H26.7578V22.6211H23.7695V18.1211H18.9062Z" fill="currentColor"/>
              </svg>

            </p>
            <p className="text-lg text-xs leading-3 tracking-tight text-gray-darker dark:text-white">Nhà cung cấp (NCC)</p>
          </li>
          <li className="flex-1 rounded-2xl bg-white py-2 pt-0 pt-2 text-center dark:bg-[#15223B]">
            <p className="flex items-center justify-center gap-[2px] pl-[6px] text-[42px] text-primary dark:text-darkPrimary">
              <span className="text-xl font-black"> {currentData?.producerCount} </span>
              {/* <svg width="34" height="51" viewBox="0 0 34 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.96094 31.0869V25.8574H11.4717V17.9619H16.7012V25.8574H25.2324V31.0869H16.7012V38.9619H11.4717V31.0869H2.96094Z" fill="currentColor"/>
                <path d="M18.9062 18.1211V15.1328H23.7695V10.6211H26.7578V15.1328H31.6328V18.1211H26.7578V22.6211H23.7695V18.1211H18.9062Z" fill="currentColor"/>
              </svg> */}
              <svg width="20" height="40" viewBox="0 0 34 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.96094 31.0869V25.8574H11.4717V17.9619H16.7012V25.8574H25.2324V31.0869H16.7012V38.9619H11.4717V31.0869H2.96094Z" fill="currentColor"/>
                <path d="M18.9062 18.1211V15.1328H23.7695V10.6211H26.7578V15.1328H31.6328V18.1211H26.7578V22.6211H23.7695V18.1211H18.9062Z" fill="currentColor"/>
              </svg>

            </p>
            <p className="text-lg text-xs leading-3 tracking-tight text-gray-darker dark:text-white">Nhà sản xuất (NSX)</p>
          </li>
          <li className="flex-1 rounded-2xl bg-white py-2 pt-0 pt-2 text-center dark:bg-[#15223B]">
            <p className="flex items-center justify-center gap-[2px] pl-[6px] text-[42px] text-primary dark:text-darkPrimary">
              <span className="text-xl font-black"> {currentData?.shopWarehouseCount} </span>
              {/* <svg width="34" height="51" viewBox="0 0 34 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.96094 31.0869V25.8574H11.4717V17.9619H16.7012V25.8574H25.2324V31.0869H16.7012V38.9619H11.4717V31.0869H2.96094Z" fill="currentColor"/>
                <path d="M18.9062 18.1211V15.1328H23.7695V10.6211H26.7578V15.1328H31.6328V18.1211H26.7578V22.6211H23.7695V18.1211H18.9062Z" fill="currentColor"/>
              </svg> */}
              <svg width="20" height="40" viewBox="0 0 34 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.96094 31.0869V25.8574H11.4717V17.9619H16.7012V25.8574H25.2324V31.0869H16.7012V38.9619H11.4717V31.0869H2.96094Z" fill="currentColor"/>
                <path d="M18.9062 18.1211V15.1328H23.7695V10.6211H26.7578V15.1328H31.6328V18.1211H26.7578V22.6211H23.7695V18.1211H18.9062Z" fill="currentColor"/>
              </svg>
            </p>
            <p className="text-lg text-xs leading-3 tracking-tight text-gray-darker dark:text-white">Shop kho</p>
          </li>
          <li className="flex-1 rounded-2xl bg-white py-2 pt-0 pt-2 text-center dark:bg-[#15223B]">
            <p className="flex h-10 items-center justify-center gap-[2px] pl-[6px] text-[42px] text-primary dark:text-darkPrimary">
              <span className="text-xl font-black"> &gt; {productCount} </span>
            </p>
            <p className="text-lg text-xs leading-3 tracking-tight text-gray-darker dark:text-white">Sản phẩm</p>
          </li>
        </ul>
      </div>
    </section>
   );
}

export default SectionStatistics;