// VerticalBarChart.tsx
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { useQuery } from 'react-query';
import { useProvinces } from '../../context/ProvinceContext';
import tradePromotionApi from '../../api/service/TradePromotionService';
import { useTheme } from '../../context/ThemeContext';

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const VerticalBarChart: React.FC = () => {
  const { currentProvince } = useProvinces();
  const [chartData, setChartData] = useState<any>();
  const { theme } = useTheme();
  const [toggleColor, setToggleColor] = useState<string>('#0A1118');

  const dummyData = {
    "labels": [
      "HUYỆN BA BỂ",
      "HUYỆN CHỢ ĐỒN",
      "HUYỆN CHỢ MỚI",
      "HUYỆN NGÂN SƠN",
      "HUYỆN NA RÌ",
      "HUYỆN PÁC NẶM",
      "HUYỆN BẠCH THÔNG",
    ],
    "datasets": [
    {
      "label": "",
      "data": [
        4332,
        317,
        441.5,
        95.2,
        553,
        50,
        494,
        1516
      ],
      "backgroundColor": "#4B84F7",
      "borderColor": "#4B84F7",
      "borderWidth": 1
    }
  ]
}

  useEffect(() => {
    setToggleColor(theme === 'light' ? '#0A1118' : '#fff');
  }, [theme]);

  useQuery(
    ['getShopDistrictStart', currentProvince],
    () => tradePromotionApi.getShopDistrictStart(currentProvince?.id),
    {
      onSuccess: (response: any) => {
        const transformData = (dataArray: {
          district: { id: number; name: string };
          productCount: number;
        }[]): { labels: string[]; values: number[] } => {
          return {
            labels: dataArray?.map(item => item.district.name),
            values: dataArray?.map(item => item.productCount),
          };
        };

        const chartDataRaw = transformData(response?.districtStats)

        const chartDataTransform = {
          labels: chartDataRaw?.labels,
          datasets: [
            {
              label: '',
              data: chartDataRaw?.values,
              backgroundColor: theme === 'light' ? '#4B84F7' : '#5CDBE1',
              borderColor: theme === 'light' ? '#4B84F7' : '#5CDBE1',
              borderWidth: 1,
            },
          ],
        };

        setChartData(chartDataTransform)
      },
      enabled: Boolean(currentProvince?.id),
    }
  );

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    legend: {
      labels: {
        color: toggleColor,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: '',
        },
        ticks: {
          color: toggleColor,
        },
      },
      y: {
        beginAtZero: true,
        // min: 0,        // Set the minimum value for the y-axis
        // max: 100,      // Set the maximum value for the y-axis
        title: {
          display: true,
          text: 'Tấn',
          color: toggleColor,
        },
        ticks: {
          color: toggleColor,
          // stepSize: 1000,
          // padding: 
        },
      },
    },
  };

  return (
    <div className='flex h-full w-full flex-1 flex-col pl-2 pr-4'>
      <div className="flex h-[84%] items-end pt-10">
        {chartData && <Bar data={currentProvince?.id === 'BK' ? dummyData : chartData} options={options} className="!h-full"/>}
      </div>
      <div className="relative mt-auto w-full pb-10">
        <p className="absolute left-6 top-0 text-[10px] font-bold italic text-[#4A6497]">Nguồn: VStore {currentProvince?.name}</p>
      </div>
    </div>
  )
};

export default VerticalBarChart;
