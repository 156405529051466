import axiosClient from "../AxiosClient";

const provincesApi = {
  getProvinces: () => {
    const idVietnam = '240';
    const url = `/provinces/${idVietnam}`;
    return axiosClient.get(url);
  },
};

export default provincesApi;
