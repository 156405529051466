
import iconPlay from '../../assets/images/common/icon_play.png';
import mediaImg01 from '../../assets/images/home/media_img_01.jpg';
import mediaImg02 from '../../assets/images/home/media_img_02.jpg';
import mediaImg03 from '../../assets/images/home/media_img_03.jpg';
import { useProvinces } from '../../context/ProvinceContext';

const SectionShopLive: React.FC = () => {
  const { currentProvince } = useProvinces();

  return (
    <div className="flex-1 rounded-lg">
      <div className="mb-4">
        <div className="border-b border-[#E9E9E9] pb-4 pt-3">
          <div className="mb-[14px] flex items-center justify-between">
            <div className="flex flex-1 items-center gap-1 pl-[2px] text-[#4B84F7] dark:text-[#5CDBE1]">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4166 6.66991C10.3069 6.67055 10.1982 6.64953 10.0967 6.60806C9.99517 6.56659 9.90282 6.50548 9.82495 6.42825L6.49162 3.09491C6.41392 3.01721 6.35229 2.92497 6.31024 2.82345C6.26819 2.72194 6.24654 2.61313 6.24654 2.50325C6.24654 2.28133 6.3347 2.0685 6.49162 1.91158C6.64854 1.75466 6.86137 1.6665 7.08329 1.6665C7.19317 1.6665 7.30198 1.68815 7.4035 1.7302C7.50501 1.77225 7.59725 1.83388 7.67495 1.91158L10.4166 4.66158L13.1583 1.91158C13.3152 1.75466 13.528 1.6665 13.75 1.6665C13.9719 1.6665 14.1847 1.75466 14.3416 1.91158C14.4985 2.0685 14.5867 2.28133 14.5867 2.50325C14.5867 2.72516 14.4985 2.93799 14.3416 3.09491L11.0083 6.42825C10.9304 6.50548 10.8381 6.56659 10.7365 6.60806C10.635 6.64953 10.5263 6.67055 10.4166 6.66991Z" fill="currentColor"/>
                <path d="M16.5667 6.51973C14.2667 4.49473 6.56667 4.49473 4.26667 6.51973C3.15833 7.50306 2.5 9.41973 2.5 11.6697C2.5 13.9197 3.15833 15.8364 4.26667 16.8197C5.375 17.8031 7.91667 18.3364 10.4167 18.3364C12.9167 18.3364 15.4167 17.8364 16.5667 16.8197C17.675 15.8364 18.3333 13.9197 18.3333 11.6697C18.3333 9.41973 17.675 7.50306 16.5667 6.51973ZM12.6167 12.2781L9.39167 13.8864C9.28911 13.9373 9.17529 13.9612 9.06092 13.956C8.94655 13.9508 8.83539 13.9166 8.73789 13.8566C8.64038 13.7966 8.55974 13.7128 8.50355 13.613C8.44735 13.5133 8.41746 13.4009 8.41667 13.2864V10.0531C8.41746 9.93857 8.44735 9.82617 8.50355 9.72642C8.55974 9.62667 8.64038 9.54285 8.73789 9.48285C8.83539 9.42285 8.94655 9.38863 9.06092 9.38342C9.17529 9.37821 9.28911 9.40217 9.39167 9.45306L12.6167 11.0614C12.7287 11.1187 12.8227 11.2058 12.8884 11.3132C12.954 11.4205 12.9888 11.5439 12.9888 11.6697C12.9888 11.7956 12.954 11.9189 12.8884 12.0263C12.8227 12.1336 12.7287 12.2207 12.6167 12.2781Z" fill="currentColor"/>
              </svg>
              <h3 className="pt-[1px] text-sm font-semibold leading-6 tracking-wide text-[#0A1118] dark:text-white">Truyền thông</h3>
            </div>
            <span className="inline-flex text-xs tracking-wide text-[#4B84F7] dark:text-[#5CDBE1]">
              <span>Xem thêm</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12.6668L10.6667 8.00016L6 3.3335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </span>
          </div>
          {/* <div className="relative mb-4">
            <img src={mediaImg01} alt="" className="w-full"/>
            <p className="item-center absolute bottom-0 left-0 flex items-center gap-4 px-6 py-[22px] text-lg leading-[1.1] tracking-wide text-white">
              <img src={iconPlay} alt=""/>
              <span>
                {currentProvince?.name} đang thu hút ngày
                <br/>
                càng nhiều các tập đoàn công nghệ lớn
              </span>
            </p>
          </div> */}
          <div className="relative mb-4 max-w-full">
            <div className="custom-iframe">
              <iframe width="310" height="63" src="https://www.youtube.com/embed/wqgWexhMGmc?si=zdCSVs2TVCSckjck?autoPlay=1&mute=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
          <div className="relative flex gap-4">
            <div className='flex flex-col'>
              <img src={mediaImg02} alt="" className="mb-2 w-full"/>
              <p className="mt-auto text-sm font-semibold tracking-wide">Thành phố Bắc Kạn: Đổi mới tư duy làm việc, tích cực, hiệu quả hơn</p>
            </div>
            <div className='flex flex-col'>
              <img src={mediaImg03} alt="" className="mb-2 w-full"/>
              <p className="mt-auto text-sm font-semibold tracking-wide">Mời gọi đầu tư các dự án sản xuất, kinh doanh vào Cụm Công nghiệp Bắc Kạn</p>
            </div>
          </div>
          <div className="relative mt-4 flex gap-4">
            <div className='flex flex-col'>
              <img src={mediaImg02} alt="" className="mb-2 w-full"/>
              <p className="mt-auto text-sm font-semibold tracking-wide">Thành phố Bắc Kạn: Đổi mới tư duy làm việc, tích cực, hiệu quả hơn</p>
            </div>
            <div className='flex flex-col'>
              <img src={mediaImg03} alt="" className="mb-2 w-full"/>
              <p className="mt-auto text-sm font-semibold tracking-wide">Mời gọi đầu tư các dự án sản xuất, kinh doanh vào Cụm Công nghiệp Bắc Kạn</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionShopLive;