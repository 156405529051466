import { useState, useEffect, useRef } from "react";
import iconSelect from '../../assets/images/common/icon_select.svg';
import iconSelectWhite from '../../assets/images/common/icon_select_white.svg';
import { useTheme } from '../../context/ThemeContext';
import { useProvinces } from '../../context/ProvinceContext';
import logoSurface from '../../assets/images/common/logo_surface.svg';
import provincesApi from "../../api/service/ProvincesService";
import { useQuery } from "react-query";
import { IProvince } from "../../types/IProvinces";


const ProvincesSelect: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<IProvince>();
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const { setProvince } = useProvinces();
  const selectRef = useRef<HTMLDivElement>(null);

  const [provincesData, setProvincesData] = useState<any>(null);
  
  useQuery(
    {
      queryFn: () => {
        return provincesApi.getProvinces();
      },
      onSuccess: (response: any) => {
        setProvincesData(response?.data);
        const initProvince = 'HN_01';
        const loadedProvince = response?.data.find((item: { id: string; }) => item?.id === initProvince)

        setSelectedOption(loadedProvince)
      },
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  const handleOptionClick = (option: IProvince) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedOption) {
      return setProvince(selectedOption);
    }
  }, [selectedOption, setProvince])
  

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div className="relative cursor-pointer" ref={selectRef}>
      <div className="border-gray flex h-8 min-w-[130px] items-center gap-[7px] rounded-md border px-[6px] dark:border-transparent dark:bg-darkGray" onClick={() => setIsOpen(!isOpen)}>
        <img src={logoSurface} alt="" />
        {selectedOption ? selectedOption.name : ''}
        <span className={`ml-auto text-[#0A1118] dark:text-white ${isOpen ? "rotate-180" : ""}`}>
          {theme === 'light' ? (
            <img src={iconSelect} alt="" />
          ) : (
            <img src={iconSelectWhite} alt="" />
          )}
        </span>
      </div>
      {isOpen && (
        <ul className="absolute top-[calc(100%_-_1px)] z-50 max-h-[350px] w-full overflow-auto border border-gray-300 bg-white dark:border-darkGray dark:bg-darkGray">
          {provincesData?.map((option: IProvince) => {
            const { id, name } = option
            return (
              <li
                key={id}
                className={`cursor-pointer p-2 text-center dark:text-white hover:text-primary dark:hover:text-darkPrimary ${id === selectedOption?.id ? 'hidden' : ''}`}
                onClick={() => handleOptionClick(option)}
              >
                {name}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  );
};

export default ProvincesSelect;
