import React, { createContext, useContext, useState, useEffect } from 'react';
import { IProvince } from '../types/IProvinces';


interface ProvinceContextType {
  currentProvince: IProvince;
  setProvince: (payload: IProvince) => void;
  isFullScreen: boolean;
  setFullscreen: (payload: boolean) => void;
}

const ProvinceContext = createContext<ProvinceContextType | undefined>(undefined);

export const useProvinces = () => {
  const context = useContext(ProvinceContext);
  if (!context) {
    throw new Error('useProvinces must be used within a ThemeProvider');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

export const ProvinceProvider: React.FC<Props> = ({ children }) => {
  const [currentProvince, setCurrentProvince] = useState<IProvince>(null as unknown as IProvince);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const setProvince = (newProvince: IProvince) => {
    setCurrentProvince(newProvince);
  };

  const setFullscreen = (isFull: boolean) => {
    setIsFullScreen(isFull);
  };

  useEffect(() => {
  }, []);

  return (
    <ProvinceContext.Provider value={{ currentProvince, setProvince, isFullScreen, setFullscreen }}>
      {children}
    </ProvinceContext.Provider>
  );
};
