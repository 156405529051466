
import { useState } from 'react';
import BlockTitle from '../../components/common/BlockTitle';
import { useProvinces } from '../../context/ProvinceContext';
import MapComponent from '../common/MapComponent';
import VerticalBarChart from './VerticalBarChart';

const SectionShopLocation: React.FC = () => {
  const { currentProvince } = useProvinces();
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const tabLabel = [
    'Phân bố hàng hóa',
    'Vị trí Shop Kho'
  ]

  const tabsContent = [
    <VerticalBarChart />,
    <MapComponent />
  ]

  return (
    <div className="flex-1 flex-col rounded-lg bg-white dark:bg-[#15223B]">
      <div className="mb-4 flex h-full flex-col">
        <div className="min-h-[126px] border-b-2 border-[#E9E9E9] pb-[10px] pl-3 pr-4 pt-3 dark:border-[#1D2E4F]">
          <div className="flex items-start justify-between">
            <BlockTitle title={`Vị trí Shop kho trên địa bàn ${currentProvince?.name}`} />
          </div>
          <div className="my-3 flex items-center gap-2 ">
            {tabLabel.map((tab, index) => (
              <button
                key={index}
                onClick={() => handleTabClick(index)}
                className={`text-sm text-[#4B6FB4] ${index === activeTab ? 'text-white inline-flex h-8 min-w-max items-center gap-[6px] rounded-lg bg-[#4B84F7] px-2 dark:bg-[#263F70]' : ''}`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-1 items-end">
          {/* {tabsContent[activeTab]} */}
          <VerticalBarChart />,
        </div>
      </div>
    </div>
  );
}

export default SectionShopLocation;