import { useState, useEffect } from 'react';

const CurrentDateTime = () => {
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const date = new Date();
      const offset = 7 * 60;
      const localTime = date.getTime();
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = localTime + localOffset;
      const bangkokTime = new Date(utc + offset * 60000);

      const formattedDate = bangkokTime.toLocaleDateString('vi-VN', {
        weekday: 'long',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });

      const formattedTime = bangkokTime.toLocaleTimeString('vi-VN', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      });

      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="border-gray flex h-8 min-w-[220px] items-center gap-3 rounded-md border px-[6px] font-normal dark:border-transparent dark:bg-darkGray text-xs lg:text-sm">
      <p>{currentDate}</p>
      <p className="text-primary">{currentTime}</p>
    </div>
  );
}

export default CurrentDateTime;
