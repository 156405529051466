import React from 'react';
import Header from '../components/Header';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="layout bg-[#F4F5FA] text-[#0A1118] dark:bg-[#0E1729] dark:text-white">
      <Header></Header>
      <main className="pb-[70px]">
        {children}
      </main>
      <footer className="footer">
        {/* <p>© 2024 Your Company</p> */}
      </footer>
    </div>
  );
};

export default Layout;
