import iconSearch from '../../assets/images/common/icon_search.svg';
import iconSetting from '../../assets/images/common/icon_setting.svg';
import BlockTitle from '../../components/common/BlockTitle';
import ProductsDetailTable from '../../components/home/ProductsDetailTable';
import { useProvinces } from '../../context/ProvinceContext';

const SectionQuantityProductChart: React.FC = () => {
  const { currentProvince } = useProvinces();

  return (
    <div className="flex-1 rounded-lg bg-white dark:bg-[#15223B]">
      <div className="rounded-lg">
        <div className="border-b-2 border-[#E9E9E9] pb-4 pl-6 pr-4 pt-3 dark:border-[#1D2E4F]">
          <div className="flex items-start justify-between">
            <BlockTitle title={`Sản phẩm niêm yết trên VStore ${currentProvince?.name}`} />
            <div className="flex gap-2 pt-1">
              <button className="inline-flex h-8 items-center gap-[6px] rounded-lg px-2">
                <img src={iconSearch} alt="" />
                <span className="text-sm text-[#4B6FB4]">Tìm kiếm</span>
              </button>
              <button>
                <img src={iconSetting} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="custom-height overflow-auto pb-2">
          <ProductsDetailTable />
        </div>
      </div>
    </div>
  )
}

export default SectionQuantityProductChart;