import { Link } from 'react-router-dom';
import headerLogo from '../assets/images/common/header_logo.svg';
import headerLogoWhite from '../assets/images/common/header_logo_white.svg';
import iconSun from '../assets/images/common/icon_sun.svg';
import iconMoon from '../assets/images/common/icon_moon.svg';
import CurrentTime from '../components/common/CurrentTime'
import ProvincesSelect from '../components/header/ProvincesSelect'
import { useTheme } from '../context/ThemeContext';

const Header = () => {
  const { theme, toggleTheme } = useTheme();

  const navbarItems = [
    {
      text: 'Giới thiệu',
      link: '#'
    },
    {
      text: 'Truyền thông',
      link: '#'
    },
    {
      text: 'Shoplive địa phương',
      link: '#'
    },
    {
      text: 'Biểu đồ thống kê',
      link: '#'
    },
    {
      text: 'Số liệu thống kê',
      link: '#'
    },
    {
      text: 'VStore quản trị địa phương',
      link: '#'
    },
  ]

  return (
    <header className="border-gray w-full border-b bg-white py-4 text-sm font-semibold text-[#0A1118] dark:border-[#27324A] dark:bg-darkWhite dark:text-white">
      <div className="wrapper flex flex-wrap items-center gap-x-9 gap-y-2 flex-nowrap">
        <h1>
          {theme === 'light' ? (
            <img src={headerLogo} alt="V-STORE VIPTAM GROUP" />
          ) : (
            <img src={headerLogoWhite} alt="V-STORE VIPTAM GROUP" />
          )}
        </h1>
        <nav>
          <ul className="flex gap-2 lg:gap-5">
            { navbarItems.map((item, index) => {
              const { link, text } = item;
              return (
                <li key={index} className="text-xs tracking-wide lg:text-sm">
                  <Link to={link} className="">{ text }</Link>
                </li>
              )
            })}
          </ul>
        </nav>
        <div className="ml-auto flex gap-2">
          <div className="trans border-gray flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border bg-white transition duration-200 ease-linear hover:opacity-60 dark:border-transparent dark:bg-darkGray"
            onClick={toggleTheme}
          >
            {theme === 'light' ? (
              <img src={iconSun} alt="" />
            ) : (
              <img src={iconMoon} alt="" />
            )}
          </div>
          <CurrentTime />
          <ProvincesSelect />
        </div>
      </div>
    </header>
   );
}

export default Header;