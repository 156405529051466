import { useState, useRef, useEffect } from 'react';
import Header from '../Header';
import { useTheme } from '../../context/ThemeContext';
import { useProvinces } from '../../context/ProvinceContext';
import iconFullscreen from '../../assets/images/common/icon_fullscreen.svg';
import iconFullscreenWhite from '../../assets/images/common/icon_fullscreen_white.svg';

const ToggleFullscreen: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();
  const { setFullscreen } = useProvinces();

  const handleFullscreenToggle = () => {
    if (chartContainerRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        chartContainerRef.current.requestFullscreen();
      }
    }
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
      setFullscreen(true)
    } else {
      setIsFullscreen(false);
      setFullscreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <div className={`relative fullscreen flex-1 rounded-lg ${isFullscreen ? 'bg-[#F4F5FA] dark:bg-[#0E1729] pb-10 w-full fixed top-0 left-0 flex items-center justift-center min-h-screen overflow-auto' : ''}`}
      ref={chartContainerRef}>
      <div className='flex h-full w-full flex-col'>
        {isFullscreen && <Header />}
        {!isFullscreen &&
          <div className="wrapper flex mt-5 mb-5" style={{ marginTop: '20px' }}>
            <button onClick={handleFullscreenToggle} className="flex top-4 z-10 cursor-pointer rounded-full border-none bg-white p-2 shadow transition duration-200 ease-linear hover:opacity-70 dark:bg-darkGray">
              {theme === 'light' ? (
                <img src={iconFullscreen} alt="" className="h-7 w-7" />
              ) : (
                <img src={iconFullscreenWhite} alt="" className="h-7 w-7" />
              )}
            </button>
          </div>
        }
        <div className="mt-2 flex flex-1 flex-col">
          {children}
        </div>
      </div>
    </div>
  );
}

export default ToggleFullscreen;