import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useQuery } from 'react-query';
import { useProvinces } from '../../context/ProvinceContext';
import tradePromotionApi from '../../api/service/TradePromotionService';
import { useTheme } from '../../context/ThemeContext';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

interface Dataset {
  label: string;
  data: number[];
  backgroundColor: string;
  borderWidth: number;
}

interface ChartDataType {
  labels: string[];
  datasets: Dataset[];
}


const HorizontalBarChart: React.FC = () => {
  const { currentProvince } = useProvinces();
  const { theme } = useTheme();
  const [toggleColor, setToggleColor] = useState<string>('#0A1118');
  const [ids, setIds] = useState<string[]>()
  const chartRef = useRef<any>(null);

  useEffect(() => {
    setToggleColor(theme === 'light' ? '#0A1118' : '#fff');
  }, [theme]);

  const { data: productStatsData, isLoading, isError } = useQuery(
    ['getCategorizeProductStats', currentProvince],
    () => tradePromotionApi.getCategorizeProductStats({ provinceId: currentProvince?.id }),
    {
      onSuccess: (response: any) => {
        const idsFilter = response?.categoryStats?.map((item: any) => {
          return item?.category?.id
        });
        setIds(idsFilter)
      },
      enabled: Boolean(currentProvince?.id),
    }
  );

  const { data: productStatsOutTownData } = useQuery(
    ['getCategorizeProductStatsOutTown', productStatsData],
    () => tradePromotionApi.getCategorizeProductStatsOutTown({
      categoryId: ids,
      excludeProvinceId: currentProvince?.id
    }),
    {
      onSuccess: (response: any) => {
      },
      enabled: Boolean(productStatsData),
    }
  );

  const processedData = useMemo(() => {
    if (!productStatsOutTownData) return { categories: [], datasets: [] };
    const changeFormatChart = (array: any, type: string) => {
      return array?.map((item: any) => ({
        category: item.category?.name,
        type,
        value: item.productCount,
      }));
    }

    const sumData = Array.from(new Set([...changeFormatChart(productStatsData?.categoryStats, 'Địa phương'), ...changeFormatChart(productStatsOutTownData?.categoryStats, 'Ngoài địa phương')])).sort((a, b) => a - b);
    
    const incrementValues = (value: number) => {
      return value + Math.floor(Math.random() * 30000) + 1
    };

    const dummyData = [
      { category: 'Nông sản', type: 'Ngoài địa phương', value: incrementValues(7543)},
      { category: 'Nông sản', type: 'Địa phương', value: incrementValues(39333)},
      { category: 'Hoa quả', type: 'Ngoài địa phương', value: incrementValues(5021)},
      { category: 'Hoa quả', type: 'Địa phương', value: incrementValues(36586)},
      { category: 'Thực phẩm', type: 'Ngoài địa phương', value: incrementValues(31180) },
      { category: 'Thực phẩm', type: 'Địa phương', value: incrementValues(3581)},
      { category: 'Sức khỏe', type: 'Ngoài địa phương', value: incrementValues(14884) },
      { category: 'Sức khỏe', type: 'Địa phương', value: incrementValues(5281) },
      { category: 'Giải trí', type: 'Ngoài địa phương', value: incrementValues(28333)},
      { category: 'Giải trí', type: 'Địa phương', value: incrementValues(28333) },
      { category: 'Tiêu dùng', type: 'Ngoài địa phương', value: incrementValues(8333)},
      { category: 'Tiêu dùng', type: 'Địa phương', value: incrementValues(32586)},
      { category: 'Gia dụng', type: 'Ngoài địa phương', value: incrementValues(2333)},
      { category: 'Gia dụng', type: 'Địa phương', value: incrementValues(20333)},
      { category: 'Nội thất', type: 'Ngoài địa phương', value: incrementValues(4033)},
      { category: 'Nội thất', type: 'Địa phương', value: incrementValues(30333)},
      { category: 'Điện tử', type: 'Ngoài địa phương', value: incrementValues(12002)},
      { category: 'Điện tử', type: 'Địa phương', value: incrementValues(27333)},
      { category: 'Mỹ phẩm', type: 'Ngoài địa phương', value: incrementValues(15022)},
      { category: 'Mỹ phẩm', type: 'Địa phương', value: incrementValues(27696)},
    ];

    const finalData = currentProvince?.id === 'BK' ? dummyData : sumData;


    const categories = Array.from(new Set(finalData.map((item: any) => item.category)));
    const types = Array.from(new Set(finalData.map((item: any) => item.type)));

    const datasets = types.map((type, index) => ({
      label: type,
      data: categories.map(category => {
        const item = finalData.find((d: any) => d.category === category && d.type === type);
        return item ? item.value : 0;
      }),
      backgroundColor: index % 2 === 0 ? '#D25A65' : '#629AF7',
      borderWidth: 1,
    }));

    return { categories, datasets };
  }, [productStatsData, productStatsOutTownData, currentProvince?.id]);

  const chartData: ChartDataType = {
    labels: processedData.categories as string[],
    datasets: processedData.datasets as Dataset[],
  };

  const options = {
    indexAxis: 'y' as const,
    scales: {
      x: {
        beginAtZero: true,
        barThickness: 20,
        categoryPercentage: 1.0,
        barPercentage: 1.0,
        ticks: {
          color: toggleColor,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: toggleColor,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems: any) => tooltipItems[0].label,
          label: (tooltipItem: any) => `${tooltipItem.label}: ${tooltipItem.raw}`,
        },
        titleColor: toggleColor,
        bodyColor: toggleColor,
        backgroundColor: theme === 'light' ? '#fff' : '#000',
      },
      legend: {
        labels: {
          color: toggleColor,
        },
      },
    },
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  return (
    <div className="chart-container flex w-[90%] items-center text-center ">
      <Bar data={chartData} options={options} className="!h-auto !w-full"
        ref={(chart) => {
          if (chart) {
            chartRef.current = chart;
          }
        }}
      />
    </div>
  );
};

export default HorizontalBarChart;
