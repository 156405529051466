import iconChartWhite from '../../assets/images/common/icon_chart_white.svg';
import iconSearch from '../../assets/images/common/icon_search.svg';
import iconSetting from '../../assets/images/common/icon_setting.svg';
import iconInfo from '../../assets/images/common/icon_info.svg';
import BlockTitle from '../../components/common/BlockTitle';
import HorizontalBarChart from '../../components/home/HorizontalBarChart';
import { useQuery } from 'react-query';
import tradePromotionApi from '../../api/service/TradePromotionService';
import { useState, useRef, useEffect } from 'react';
import { useProvinces } from '../../context/ProvinceContext';
import { useTheme } from '../../context/ThemeContext';

const SectionProductChart: React.FC = () => {
  const { currentProvince } = useProvinces();
  const [totalProducts, setTotalProducts] = useState<string>('');
  const { theme } = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);

  useQuery(
    ['getProductOutput', currentProvince?.id], {
    queryFn: () => {
      return tradePromotionApi.getProductOutput(currentProvince?.id);
    },
    onSuccess: (response: any) => {
      const total = new Intl.NumberFormat('en-US').format(currentProvince?.id === 'BK' ? 50000 + Math.floor(Math.random() * 10000) + 1 : response?.productCount);
      setTotalProducts(total)
    },
    enabled: !!currentProvince?.id,
  });

  return (
    <div className={`flex-1 rounded-lg bg-white dark:bg-[#15223B]`}
      ref={chartContainerRef}>
      <div className="mb-4 flex h-full w-full flex-col items-center justify-center pb-4">
        <div className="relative w-full border-b-2 border-[#E9E9E9] pb-4 pl-6 pr-4 pt-3 dark:border-[#1D2E4F]">
          <div className="flex items-start justify-between">
            <BlockTitle title="Sản lượng sản phẩm theo thời gian và khu vực" />
            <div className="flex gap-2 pt-1">
              <button className="inline-flex h-8 items-center gap-[6px] rounded-lg px-2">
                <img src={iconSearch} alt="" />
                <span className="text-sm text-[#4B6FB4]">Tìm kiếm</span>
              </button>
              <button className="inline-flex h-8 min-w-max items-center gap-[6px] rounded-lg bg-[#4B84F7] px-2 dark:bg-[#263F70]">
                <img src={iconChartWhite} alt="" />
                <span className="text-sm text-white">Biểu đồ</span>
              </button>
              <button>
                <img src={iconSetting} alt="" />
              </button>
              <button>
                <img src={iconInfo} alt="" />
              </button>
            </div>
          </div>
          <div className="relative flex items-end">
            <span className="text-[40px] lg:text-[30px] font-semibold leading-8 tracking-tight text-[#629AF7] dark:text-[#5CDBE1]">{totalProducts}</span>
            {/* <span className="ml-[6px] text-base leading-4 tracking-tight text-[#11FF1B]">(+2,5%)</span> */}
          </div>
          <p className="mt-2 text-sm text-[#4A6497]">Sản lượng trung bình</p>
        </div>
        <div className="w-full flex-1 px-6 pt-5">
        <div className="relative w-full max-w-[1920px] ml-10">
          <p className="absolute -left-14 top-1/2 -rotate-90 text-xs font-bold text-primary">Loại sản phẩm</p>
          <HorizontalBarChart />
          <div className="relative w-full mt-4">
            <p className="absolute left-6 top-0 text-[10px] font-bold italic text-[#4A6497]">Nguồn: VStore {currentProvince?.name}</p>
            <p className="text-center text-xs font-bold text-[#4B84F7]">Sản phẩm</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default SectionProductChart;